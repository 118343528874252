/**
 * Created by vladislav on 06.05.2020
 */

var GridPathView = cc.Node.extend({
    ctor: function (round, index, playerId) {
        this._super();

        this.round = round;
        this.index = index;
        this.playerId = playerId;
        this.prevIndex = cleverapps.knockoutGame.rumble.rounds[this.round.id - 1].players.indexOf(this.playerId);

        this.setAnchorPoint(0.5, 0.5);

        var styles = cleverapps.styles.GridPathView.paths[this.round.id - 1][this.prevIndex];
        this.path = new cleverapps.Spine(styles.json);
        this.path.setScale(styles.scaleX, styles.scaleY);
        this.addChild(this.path);

        this.setPositionRound(this.calcPosition());
        this.setLocalZOrder(-1);
    },

    animate: function (silent, delay) {
        if (silent) {
            this.path.setAnimation(0, "idle", true);
        } else {
            this.path.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.CallFunc(function () {
                    this.path.setAnimation(0, "animation", false);
                }.bind(this))
            ));
        }
    },

    calcPosition: function () {
        var styles = cleverapps.styles.GridPlayerView;

        var start = styles.positions[this.round.id - 1][this.prevIndex];
        var finish = styles.positions[this.round.id][this.index];
        var dist = cc.p(finish.x - start.x, finish.y - start.y);
        if (this.round.id !== 3) {
            return {
                x: { align: "center", dx: -dist.x / 2 },
                y: { align: "center", dy: -dist.y / 2 }
            };
        } 
        return {
            x: { align: "center", dx: -dist.x / 2 },
            y: { align: "center" }
        };
    },

    fadeOut: function () {
        this.path.setAnimation(0, "fadeout", false);
    }
});

cleverapps.styles.GridPathView = {
    paths: [
        [
            { scaleX: 1, scaleY: -1, json: bundles.battle.jsons.grid_path_1 },
            { scaleX: -1, scaleY: -1, json: bundles.battle.jsons.grid_path_1 },
            { scaleX: 1, scaleY: -1, json: bundles.battle.jsons.grid_path_1 },
            { scaleX: -1, scaleY: -1, json: bundles.battle.jsons.grid_path_1 },
            { scaleX: 1, scaleY: 1, json: bundles.battle.jsons.grid_path_1 },
            { scaleX: -1, scaleY: 1, json: bundles.battle.jsons.grid_path_1 },
            { scaleX: 1, scaleY: 1, json: bundles.battle.jsons.grid_path_1 },
            { scaleX: -1, scaleY: 1, json: bundles.battle.jsons.grid_path_1 }
        ],
        [
            { scaleX: 1, scaleY: -1, json: bundles.battle.jsons.grid_path_2 },
            { scaleX: 1, scaleY: -1, json: bundles.battle.jsons.grid_path_3 },
            { scaleX: 1, scaleY: 1, json: bundles.battle.jsons.grid_path_2 },
            { scaleX: 1, scaleY: 1, json: bundles.battle.jsons.grid_path_3 }
        ],
        [
            { scaleX: 1, scaleY: -1, json: bundles.battle.jsons.grid_path_4 },
            { scaleX: 1, scaleY: 1, json: bundles.battle.jsons.grid_path_4 }
        ]
    ]
};