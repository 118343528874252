/**
 * Created by vladislav on 10.02.2020
 */

var KnockoutWindow = CleverappsWindow.extend({
    onWindowLoaded: function (rumble) {
        this.rumble = rumble;

        this._super({
            title: this.getTitle(),
            name: "knockoutwindow",
            content: this.getContent(),
            closeButton: false
        });

        new ActionPlayer([
            function waitInitialization(f) {
                this.initializingText = this.createInitializingText();

                this.rumble.whenInitialize(function (success) {
                    if (cleverapps.config.debugMode && this.rumble.outcome === Rumble.OUTCOME_UNKNOWN) {
                        var button = new cleverapps.UI.Button({
                            width: 200 * resolutionScale,
                            height: 100 * resolutionScale,
                            text: "Skip",
                            onClicked: function () {
                                this.close();
                            }.bind(this)
                        });
                        this.window.addChild(button);
                        button.setPositionRound({ x: { align: "center" }, y: { align: "bottom", dy: -15 } });
                    }

                    if (success) {
                        f();
                    } else {
                        this.close();

                        cleverapps.knockoutGame.onRumbleInitFailed();
                    }
                }.bind(this));
            }.bind(this),

            function (f) {
                this.playersViews = this.rumble.rounds.map(function (round, index) {
                    return this.createPlayersForRound(round, this.rumble.rounds.length - index);
                }.bind(this));

                this.rumble.rounds.forEach(function (round, id) {
                    if (id < this.rumble.rounds.length - 1) {
                        this.drawOldRound(round);
                    }
                }, this);

                if (this.rumble.getCurrentRound().id === 0) {
                    this.firstRound(f);
                } else {
                    this.updateGrid(f);
                }
            }.bind(this),

            function (f) {
                if (this.initializingText) {
                    this.initializingText.removeFromParent(true);
                    delete this.initializingText;
                }

                f();
            }.bind(this),

            this.finalAnimation.bind(this)
        ]).play();
    },

    getTitle: function () {
        var title = {};

        var currentRound = this.rumble.getCurrentRound();

        if (this.rumble.outcome !== Rumble.OUTCOME_UNKNOWN) {
            title.text = "KnockoutWindow.Title.Victory";
        } else if (currentRound && currentRound.isLast()) {
            title.text = "KnockoutWindow.Title.Final";
        } else {
            title.text = "KnockoutWindow.Title.Round";
            title.toReplace = {
                roundId: (currentRound && currentRound.id || 0) + 1
            };
        }

        return title;
    },

    updateGrid: function (f) {
        var prevRound = this.rumble.getPrevRound();
        var nextRound = this.rumble.getCurrentRound();

        var waiter = cleverapps.wait(nextRound.players.length, f);

        nextRound.players.forEach(function (playerId) {
            var isFake = playerId !== connector.platform.getUserID();

            var delay = isFake ? Math.random() * 6 + 4 : 0;
            var silent = isFake ? Math.random() < 0.3 : false;

            var opponentId = prevRound.getOpponentId(playerId);
            this.playersViews[prevRound.id][opponentId].runLoseAnimation(silent, delay);

            this.playersViews[nextRound.id][playerId].runWinAnimation(waiter, silent, delay);
        }, this);
    },

    firstRound: function (f) {
        var round = this.rumble.getCurrentRound();

        var userIndex = round.players.indexOf(connector.platform.getUserID());
        var animated = round.players.slice(userIndex + 1);

        var waiter = cleverapps.wait(animated.length + 1, f);

        setTimeout(waiter, 500);

        var delays = animated.map(function () {
            return Math.random() * 6 + 4;
        }).sort();

        animated.forEach(function (playerId, index) {
            this.playersViews[0][playerId].runStartAnimation(delays[index], waiter);
        }, this);
    },

    finalAnimation: function () {
        if (this.rumble.outcome === Rumble.OUTCOME_UNKNOWN) {
            this.runPlayCountdown();
        } else if (this.rumble.outcome === Rumble.OUTCOME_WIN) {
            this.runVictoryAnimation();
        } else {
            setTimeout(this.close.bind(this), 3000);
        }
    },

    runVictoryAnimation: function () {
        var styles = cleverapps.styles.KnockoutWindow;

        this.grid.setAnimation(0, "fadeout", false);

        var fadeOut = function (element) {
            element && element.runAction(new cc.FadeOut(0.5));
        };

        for (var roundId in this.playersViews) {
            for (var playerId in this.playersViews[roundId]) {
                this.playersViews[roundId][playerId].fadeOut();
            }
        }

        this.window.setCascadeOpacityEnabled(false);
        this.windowTitle.setCascadeOpacityEnabledRecursively(true);
        var items = [this.window, this.windowTitle];
        if (this.decors && this.decors.decors) {
            items = items.concat(Object.values(this.decors.decors));
        }
        items.forEach(function (item) {
            if (item) {
                fadeOut(item);
            }
        });

        var targetScale = 1.3;
        var maxScale = 1.5;

        var first = this.cup.getPosition();
        var third = cc.p(this.content.width / 2, this.content.height / 2 + styles.victory.cup.dy);
        var second = cc.p(first.x + styles.victory.bezier.second.x, first.y + styles.victory.bezier.second.y);
        this.cup.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.Sequence(
                    new cc.ScaleTo(0.4, maxScale),
                    new cc.ScaleTo(0.4, targetScale)
                ),
                new cc.BezierTo(0.8, [first, second, third])
            ),
            new cc.CallFunc(function () {
                var scene = cleverapps.scenes.getRunningScene();
                this.cup.replaceParentSamePlace(scene);
                this.cup.stopAllActions();
                scene.knockoutVictoryCup = this.cup;
                this.close();
            }.bind(this))
        ));

        cleverapps.audio.playSound(bundles.victory_window.urls.win_effect);
    },

    runPlayCountdown: function () {
        var styles = cleverapps.styles.KnockoutWindow;

        var countdown = 3;

        this.countDown = new AnimatedCounterComponent({
            from: countdown,
            to: 1,
            font: cleverapps.styles.FONTS.KNOCKOUT_WINDOW_COUNTDOWN_TEXT,
            scale: 1.5,
            sfx: true,
            onFinish: this.close.bind(this)
        });
        this.content.addChild(this.countDown);
        this.countDown.setPositionRound(styles.countdownText);
        this.countDown.runAnimation();
    },

    createPlayersForRound: function (round, zOrder) {
        var roundPlayerViews = {};
        round.players.forEach(function (playerId) {
            var view = new GridPlayerView(round, playerId);
            roundPlayerViews[playerId] = view;
            this.content.addChild(view, zOrder);
        }, this);

        return roundPlayerViews;
    },

    drawOldRound: function (round) {
        round.players.forEach(function (playerId) {
            if (round.isWinner(playerId)) {
                this.playersViews[round.id][playerId].setWon();
            } else {
                this.playersViews[round.id][playerId].setLost();
            }
        }, this);
    },

    getContent: function () {
        var content = this.content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize2(cleverapps.styles.KnockoutWindow.content);

        this.content.addChild(this.createGrid());
        this.content.addChild(this.createCup());

        return content;
    },

    createGrid: function () {
        var grid = this.grid = new cleverapps.Spine(bundles.battle.jsons.grid_json);
        grid.setPositionRound(this.content.width / 2, this.content.height / 2);
        grid.setAnimation(0, "animation", true);
        this.content.setCascadeOpacityEnabled(true);
        grid.setCascadeOpacityEnabled(true);

        return grid;
    },

    createCup: function () {
        var cup = this.cup = new cc.Node();
        cup.setAnchorPoint(0.5, 0.5);
        cup.setContentSize2(cleverapps.styles.KnockoutWindow.cup);
        cup.setPositionRound(cleverapps.styles.KnockoutWindow.cup);
        cup.setLocalZOrder(10);

        var animation = new cleverapps.Spine(bundles.knockout_cup.jsons.cup_json);
        cup.addChild(animation);
        animation.setPositionRound(cup.width / 2, cup.height / 2);
        animation.setAnimation(0, "animation", true);

        cup.setScale(0.7);
        cup.baseScale = cup.scale;

        return cup;
    },

    createInitializingText: function () {
        var styles = cleverapps.styles.KnockoutWindow.loadingText;

        var msg;
        if (this.rumble.rounds.length === 0) {
            msg = Messages.get("KnockoutWindow.loading");
        } else if (this.rumble.outcome === Rumble.OUTCOME_UNKNOWN) {
            msg = Messages.get("KnockoutWindow.waitingForPlayers");
        }
        if (!msg) {
            return;
        }

        var text = cleverapps.UI.generateTTFText(msg + "   ", cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
        this.content.addChild(text);
        text.setPositionRound(styles);
        text.setDimensions(styles.width, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        var dots = "";
        var spaces = "   ";
        text.runAction(new cc.RepeatForever(
            new cc.Sequence(
                new cc.CallFunc(function () {
                    dots = (dots === "...") ? "." : (dots + ".");
                    spaces = (spaces === "") ? "  " : spaces.slice(1);
                    text.setString(msg + dots + spaces);
                    text.setPositionRound(styles);
                }),
                new cc.DelayTime(0.5)
            )
        ));

        return text;
    },

    listBundles: function () {
        return ["battle", "victory_window"];
    }
});

cleverapps.styles.KnockoutWindow = {
    content: {
        width: 600,
        height: 1070
    },
    loadingText: {
        x: { align: "left", dx: -20 },
        y: { align: "center", dy: 0 },
        width: 200
    },
    countdownText: {
        x: { align: "left", dx: 30 },
        y: { align: "center", dy: 0 }
    },
    cup: {
        width: 200,
        height: 200,
        x: { align: "center", dx: 200 },
        y: { align: "center" }
    },
    victory: {
        bezier: {
            second: {
                x: -25,
                y: 400
            }
        },
        cup: {
            dy: 50
        }
    }
};