/**
 * Created by vladislav on 11.02.2020
 */

var Rumble = function () {
    cleverapps.EventEmitter.call(this);

    this.outcome = Rumble.OUTCOME_UNKNOWN;

    this.rounds = [];

    this.inited = false;
    this.initedSuccess = 0;
    this.init(function (data) {
        if (data) {
            var savedData = cleverapps.dataLoader.load(DataLoader.TYPES.RUMBLE);
            if (savedData) {
                this.players = savedData.players;
                cleverapps.Random.state = savedData.random;
            } else {
                this.players = this.preparePlayers(data);
            }

            this.randomState = cleverapps.Random.state;

            if (savedData && savedData.rounds) {
                for (var i = 0; i < (savedData.rounds && savedData.rounds.length); i++) {
                    this.rounds.push(new Round(this, savedData.rounds[i].players, savedData.rounds[i].winners));
                }
            } else {
                var players = cleverapps.shuffle(Object.keys(this.players).filter(function (playerId) {
                    return playerId !== connector.platform.getUserID();
                }));
                players.splice(cleverapps.Random.random(0, 3), 0, connector.platform.getUserID());
                this.addRound(players);
            }
            savedData && cleverapps.dataLoader.remove(DataLoader.TYPES.RUMBLE);
            this.initedSuccess = 1;
        } else {
            this.initedSuccess = 0;
        }

        this.inited = true;
        this.trigger("initialize");
    }.bind(this));
};

Rumble.prototype = Object.create(cleverapps.EventEmitter.prototype);
Rumble.prototype.constructor = Rumble;

Rumble.prototype.changePlayerId = function (oldId, newId) {
    if (this.players[oldId]) {
        this.players[newId] = this.players[oldId];
        this.players[newId].id = newId;
        delete this.players[oldId];
    }

    for (var i = 0; i < this.rounds.length; ++i) {
        this.rounds[i].changePlayerId(oldId, newId);
    }
};

Rumble.prototype.addRound = function (players) {
    this.rounds.push(new Round(this, players));
};

Rumble.prototype.moveNextRound = function (outcome) {
    var round = this.getCurrentRound();

    round.calcWinners(outcome);

    if (!round.isWinner(connector.platform.getUserID())) {
        this.lose();
    } else {
        if (round.isLast()) {
            this.win();
        }
        this.addRound(round.winners);
    }
};

Rumble.prototype.whenInitialize = function (callback) {
    if (this.inited) {
        callback(this.initedSuccess);
    } else {
        this.on("initialize", function () {
            callback(this.initedSuccess);
        }.bind(this));
    }
};

Rumble.prototype.init = function (callback) {
    var needAmount = 7;

    var loadAvatars = function (players) {
        cleverapps.UI.Avatar.preload(players, cleverapps.waitNoMore(2000, function () {
            callback(players);
        }));
    };

    if (cleverapps.competitionPlayers.getAmount() >= needAmount) {
        loadAvatars(cleverapps.competitionPlayers.listPlayers(needAmount));
    } else {
        var waitNoMore = cleverapps.waitNoMore(5000, function () {
            delete cleverapps.competitionPlayers.onAvailableListeners.knockout;

            if (cleverapps.competitionPlayers.getAmount() >= needAmount) {
                loadAvatars(cleverapps.competitionPlayers.listPlayers(needAmount));
            } else {
                loadAvatars([]);
            }
        });

        cleverapps.competitionPlayers.reload();
        cleverapps.competitionPlayers.onAvailableListeners.knockout = waitNoMore;
    }
};

Rumble.prototype.getCurrentRound = function () {
    return this.rounds[this.rounds.length - 1];
};

Rumble.prototype.getPrevRound = function () {
    return this.rounds[this.rounds.length - 2];
};

Rumble.prototype.preparePlayers = function (players) {
    if (!players.length) {
        players = cleverapps.competitionPlayers.listFakePlayers(50);
    }

    players.push({
        id: connector.platform.getUserID(),
        name: cleverapps.user.getPlayerInfo().name,
        avatar: cleverapps.user.getPlayerInfo().avatar
    });

    var res = {};
    players.forEach(function (player) {
        res[player.id] = player;
    });

    return res;
};

Rumble.prototype.giveUp = function () {
    this.outcome = Rumble.OUTCOME_GAVEUP;
};

Rumble.prototype.win = function () {
    this.outcome = Rumble.OUTCOME_WIN;
    cleverapps.knockoutGame.onWin();
};

Rumble.prototype.lose = function () {
    this.outcome = Rumble.OUTCOME_LOSE;
    cleverapps.knockoutGame.onLose();
};

Rumble.prototype.getInfo = function () {
    return {
        players: this.players,
        random: this.randomState,
        rounds: this.rounds.map(function (round) {
            return round.getInfo();
        })
    };
};

Rumble.OUTCOME_LOSE = 0;
Rumble.OUTCOME_WIN = 1;
Rumble.OUTCOME_UNKNOWN = 3;