/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["simpleForce"] = {
    type: Placements.FREE_FOCUS_MAIN,
    priority: 9,

    filter: function () {
        return cleverapps.meta.getMainObject().isForceAvailable();
    },

    action: function () {
        cleverapps.focusManager.display({
            focus: "simpleForce",
            control: ["progress_view", "play_button"],
            actions: [
                function (f) {
                    if (cleverapps.meta.getMainObject().pendingsStars > 0) {
                        cleverapps.meta.getMainObject().updateProgress(f);
                    } else {
                        cleverapps.timeouts.setTimeout(f, 500);
                    }
                },

                function (f) {
                    cleverapps.meta.getMainObject().showForce(f);
                },

                function (f) {
                    if (cleverapps.playButton && cleverapps.playButton.passedLevel) {
                        cleverapps.playButton.animatePassLevel();
                    }
                    f();
                }
            ]
        });
    }
};

Placements.ENTRIES["simpleUpdateProgress"] = {
    type: Placements.FREE_FOCUS_MAIN,
    priority: -2,

    filter: function () {
        return cleverapps.meta.getMainObject().pendingsStars > 0 && !cleverapps.meta.getMainObject().checkCompleted();
    },

    action: function () {
        cleverapps.meta.getMainObject().updateProgress();
    }
};

Placements.ENTRIES["simpleMoveNext"] = {
    type: Placements.FREE_FOCUS_MAIN,
    priority: 10,

    filter: function () {
        return cleverapps.meta.getMainObject().checkCompleted();
    },

    action: function () {
        cleverapps.focusManager.display({
            focus: "homeMoveNextLocation",
            actions: [
                function (f) {
                    cleverapps.meta.getMainObject().onComplete(f);
                },
                function (f) {
                    cleverapps.meta.moveNextLocation();
                    f();
                },
                function (f) {
                    if (cleverapps.meta.isCompleted()) {
                        new AllDoneWindow();
                        cleverapps.focusManager.onceNoWindowsListener = f;
                        return;
                    }

                    cleverapps.focusManager.compound(f, [
                        function (f) {
                            cleverapps.meta.resetStars();
                            var bundles = cleverapps.meta.getMainObject().getBundles();
                            if (bundles) {
                                cleverapps.bundleLoader.loadBundles(bundles);
                            }
                            connector.social.markAchievement({});
                            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SIMPLE_METHA_LEVELUP);
                            f();
                        },
                        function (f) {
                            if (cleverapps.config.rpg) {
                                cleverapps.scenes.getRunningScene().startNextPage(f);
                            } else {
                                new BackgroundsWindow({ moveNext: true });
                                cleverapps.focusManager.onceNoWindowsListener = f;
                            }
                        },
                        function (f) {
                            if (cleverapps.config.rpg) {
                                f();
                            } else {
                                cleverapps.scenes.getRunningScene().moveNextBg(f);
                            }
                        },
                        function (f) {
                            if (cleverapps.config.type !== "tile3" || !TileGenerator.getNewSkins().length) {
                                f();
                                return;
                            }

                            cleverapps.focusManager.compound(f, [
                                function (f) {
                                    cleverapps.focusManager.hideAllControls();
                                    cleverapps.aims.whenAllHidden(f);
                                },

                                function (f) {
                                    new TilesUnlockWindow();
                                    cleverapps.focusManager.onceNoWindowsListener = f;
                                },

                                function (f) {
                                    cleverapps.focusManager.showControlsWhileFocused("progress_view");

                                    f();
                                }
                            ]);
                        }
                    ]);
                },
                function (f) {
                    if (cleverapps.playButton && cleverapps.playButton.passedLevel && cleverapps.config.name !== "woodenblock") {
                        cleverapps.playButton.animatePassLevel();
                    }
                    f();
                }
            ]
        });
    }
};