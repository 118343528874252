/**
 * Created by iamso on 27.10.2020
 */

var KnockoutGame = function () {
    cleverapps.EventEmitter.call(this);

    this.loseSeria = 0;
    this.winSeria = 0;
    this.lastFinishGame = 0;

    this.level = 0;

    this.load();
};

KnockoutGame.prototype = Object.create(cleverapps.EventEmitter.prototype);
KnockoutGame.prototype.constructor = KnockoutGame;

KnockoutGame.prototype.isRumble = function () {
    return this.rumble !== undefined;
};

KnockoutGame.prototype.wantsToPlay = function (f, level) {
    var isFirstLaunch = cleverapps.config.name === "differences" && levels.user.episode === 0 && levels.user.level === 0;

    cleverapps.focusManager.compound(f, [
        function (f) {
            if (this.isRumble() || isFirstLaunch) {
                f();
                return;
            }

            this.checkConnection(f);
        }.bind(this),

        function (f) {
            if (!this.isRumble()) {
                this.rumble = new Rumble();
            }

            if (isFirstLaunch) {
                this.rumble.whenInitialize(function (success) {
                    if (success) {
                        f();
                    } else {
                        this.onRumbleInitFailed();
                    }
                }.bind(this));
                return;
            }

            this.openWindow(f);
        }.bind(this),

        function (f) {
            MethaHelper.start(f, level);
        }
    ]);
};

KnockoutGame.prototype.onWin = function () {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.WIN_KNOCKOUT + this.calcTournamentType());
    this.loseSeria = 0;
    this.winSeria++;
    this.lastFinishGame = Date.now();

    if (cleverapps.weeklyCup && cleverapps.weeklyCup.isAvailable()) {
        cleverapps.weeklyCup.addAmount(1);
        cleverapps.weeklyCup.reload(true);
    }
    cleverapps.eventBus.trigger("taskEvent", DailyTasks.PASS_KNOCKOUT);
    this.save();
};

KnockoutGame.prototype.onLose = function () {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.LOSE_KNOCKOUT + this.calcTournamentType());
    this.loseSeria++;
    this.winSeria = 0;
    this.lastFinishGame = Date.now();
    this.save();
};

KnockoutGame.prototype.calcTournamentType = function () {
    var type = KnockoutGame.NORMAL_TOURNAMENT;

    if (cleverapps.user.level < 2) {
        type = KnockoutGame.BOT_CANNOT_WIN_TOURNAMENT;
    } else {
        if (cleverapps.user.level >= 10) {
            if (this.winSeria >= 4) {
                type = KnockoutGame.DIFFICULT_TOURNAMENT;
            }
            if (this.winSeria >= 7) {
                type = KnockoutGame.VERY_DIFFICULT_TOURNAMENT;
            }
        }

        var d = 0;
        if (this.lastFinishGame) {
            d = cleverapps.user.getDaysSinceRegistration(this.lastFinishGame);
        }
        if (d !== cleverapps.user.getDaysSinceRegistration()) {
            type = KnockoutGame.EASY_TOURNAMENT;
        }
        if (this.loseSeria > 0 || this.winSeria <= 1 || cleverapps.user.level < 4) {
            type = KnockoutGame.EASY_TOURNAMENT;
        }
        if (this.loseSeria > 1) {
            type = KnockoutGame.VERY_EASY_TOURNAMENT;
        }
    }

    return type;
};

KnockoutGame.prototype.stopRumble = function () {
    delete this.rumble;
};

KnockoutGame.prototype.checkConnection = function (callback) {
    cleverapps.focusManager.display({
        stack: true,
        focus: "CheckConnection",
        action: function (f) {
            var waitWindow = new WaitWindow();

            cleverapps.RestClient.get("/", {}, function () {
                waitWindow.close(true);
                f();

                callback();
            }, function () {
                waitWindow.close(true);

                cleverapps.focusManager.distract({
                    focus: "KnockoutGameNoConnection",
                    action: function (f) {
                        new NoConnectionWindow();
                        cleverapps.focusManager.onceNoWindowsListener = f;
                    }
                });
            });
        }
    });
};

KnockoutGame.prototype.onRumbleInitFailed = function () {
    this.stopRumble();

    cleverapps.focusManager.distract({
        focus: "KnockoutGameNoConnection",
        action: function (f) {
            new NoConnectionWindow();
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    });
};

KnockoutGame.prototype.openWindow = function (f, options) {
    new KnockoutWindow(this.rumble, options);
    cleverapps.focusManager.onceNoWindowsListener = f;
};

KnockoutGame.prototype.getData = function () {
    return {
        knockoutLevel: this.level
    };
};

KnockoutGame.prototype.updateData = function (data) {
    var knockoutLevel;

    if (data.knockoutLevel !== undefined) {
        knockoutLevel = data.knockoutLevel;
    } else if (data.cups !== undefined) {
        knockoutLevel = data.cups;
    } else {
        knockoutLevel = cleverapps.user.getVirtualProgress();
    }

    this.level = knockoutLevel;
};

KnockoutGame.prototype.getNextLevel = function () {
    var level = this.level;

    this.setLevel(this.level + 1);
    cleverapps.meta.save();

    return Level.CreateByNumber(level);
};

KnockoutGame.prototype.setLevel = function (level) {
    this.level = level;
};

KnockoutGame.prototype.getLevel = function () {
    return this.level;
};

KnockoutGame.prototype.load = function () {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.KNOCKOUT) || {};
    this.loseSeria = data.loseSeria || 0;
    this.winSeria = data.winSeria || 0;
    this.lastFinishGame = data.lastFinishGame || 0;
};

KnockoutGame.prototype.save = function () {
    var data = {
        loseSeria: this.loseSeria,
        winSeria: this.winSeria,
        lastFinishGame: this.lastFinishGame
    };

    cleverapps.dataLoader.save(DataLoader.TYPES.KNOCKOUT, data);
};

KnockoutGame.prototype.reset = function () {
    this.loseSeria = 0;
    this.winSeria = 0;
    this.lastFinishGame = 0;

    this.save();

    this.setLevel(0);
};

KnockoutGame.BOT_CANNOT_WIN_TOURNAMENT = 1;
KnockoutGame.VERY_EASY_TOURNAMENT = 2;
KnockoutGame.EASY_TOURNAMENT = 3;
KnockoutGame.NORMAL_TOURNAMENT = 4;
KnockoutGame.DIFFICULT_TOURNAMENT = 5;
KnockoutGame.VERY_DIFFICULT_TOURNAMENT = 6;
