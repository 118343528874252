/**
 * Created by vladislav on 06.09.2021
 */

var ProlongationOfferView = cleverapps.Layout.extend({
    ctor: function (prolongation, offer) {
        var styles = cleverapps.styles.ProlongationOfferView;

        this.prolongation = prolongation;
        this.offer = offer;

        var items = [];

        this.useProlongationTimer = this.needTimer();

        var withMoves = this.offer.moves;

        if (this.useProlongationTimer) {
            this.timer = new CircleCountdown(this.prolongation.onCompleteOffer, this.offer);
            items.push(this.timer);
        }
        if (withMoves || offer.magic) {
            this.label = this.createLabel();
            items.push(this.label);
        }

        if (withMoves && offer.bonus) {
            this.createBonusLabel();
        }

        if (offer.lottery) {
            Lottery.addIcon(this);
        }

        if (offer.rewards) {
            items.push(this.createRewards(offer.rewards));
        }

        var question = cleverapps.UI.generateTTFText(this.prolongation.getText(offer.reward), cleverapps.styles.FONTS.WINDOW_TEXT);
        question.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        question.setDimensions(styles.question.width, 0);
        question.fitTo(undefined, styles.question.height);
        items.push(question);

        this.button = this.createButton();
        items.push(this.button);

        this._super(items, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createButton: function () {
        var styles = cleverapps.styles.ProlongationOfferView.button;
        return new cleverapps.UI.Button({
            type: this.offer.magic && cleverapps.styles.UI.Button.Images.button_blue,
            content: new TextWithIcon(this.getButtonText()),
            width: styles.width,
            height: styles.height,
            onClicked: this.onClick.bind(this)
        });
    },

    updateOffer: function (offer) {
        this.offer = offer;
        this.button.setString(this.getButtonText());
        if (this.useProlongationTimer && this.timer) {
            this.timer.resumeAction();
        }
    },

    getButtonText: function () {
        return this.offer.type === Product.TYPE_FREE ? this.offer.text : Messages.get("PlayOn") + " " + this.offer.text;
    },

    createLabel: function () {
        var styles = cleverapps.styles.ProlongationOfferView;

        var label;
        if (bundles.prolongation_window.jsons.label_moves_json) {
            label = this.label = new cleverapps.Spine(bundles.prolongation_window.jsons.label_moves_json);
            label.setAnimationAndIdleAfter("open", "idle");
        } else {
            label = this.label = new cc.Sprite(bundles.prolongation_window.frames.label_moves_png);
            if (!this.offer.magic) {
                var amount = cleverapps.UI.generateImageText("+" + this.offer.moves, cleverapps.styles.FONTS.PROLONG_BUTTON_AMOUNT_TEXT);
                label.addChild(amount);
                amount.setPositionRound(styles.label.amount);
            }
        }
        return label;
    },

    createBonusLabel: function () {
        var styles = cleverapps.styles.ProlongationOfferView;
        var bonusLabel = new cleverapps.Spine(bundles.prolongation_window.jsons.bonus_json);
        this.label.addChild(bonusLabel);
        bonusLabel.setLocalZOrder(-1);
        bonusLabel.setPositionRound(styles.bonusLabel);
        bonusLabel.runAction(new cc.Sequence(
            new cc.DelayTime(1),
            new cc.CallFunc(function () {
                bonusLabel.setAnimationAndIdleAfter("showUp", "idle");
            })
        ));
    },

    calcRewardsColumns: function (rewards) {
        var amount = Object.keys(rewards).length;
        if (rewards.boosters) {
            amount -= 1;
            amount += Object.keys(rewards.boosters).length;
        }

        if (amount <= 3) {
            return amount;
        }

        return amount / 2 + amount % 2;
    },

    createRewards: function (rewards) {
        var styles = cleverapps.styles.ProlongationOfferView.rewards;

        var fg = new cc.Scale9Sprite(bundles.prolongation_window.frames.foreground_png);
        fg.setContentSize2(styles.fg);

        var rewardsList = new RewardsListComponent(rewards, {
            columns: this.calcRewardsColumns(rewards),
            font: cleverapps.styles.FONTS.PROLONG_REWARDS_TEXT,
            iconWrap: styles.rewards.iconWrap
        });
        fg.addChild(rewardsList);
        rewardsList.setPositionRound(styles.rewards);

        return fg;
    },

    onShow: function () {
        this.setVisible(false);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.4),
            new cc.ScaleTo(0, 0.5),
            new cc.Show(),
            new cc.CallFunc(function () {
                if (this.useProlongationTimer) {
                    if (!this.timer.isShown) {
                        this.timer.show();
                    } else {
                        this.timer.resumeAction();
                    }
                    this.button.runAction(new cc.RepeatForever(
                        new cc.Sequence(
                            new cc.ScaleTo(0.5, 1.1).easing(cc.easeOut(2)),
                            new cc.ScaleTo(0.5, 1).easing(cc.easeIn(2))
                        )
                    ));
                }
            }.bind(this)),
            new cc.ScaleTo(0.3, 1).easing(cc.easeBackOut()),
            new cc.CallFunc(this.showForce.bind(this))
        ));
    },

    showForce: function () {
        if (this.offer.force) {
            cleverapps.forces.create(this.button, this.offer.force, {
                importantNodes: [this.button],
                highlights: [cleverapps.windows.currentWindow()],
                actives: [this]
            });
        }
    },

    onClick: function () {
        if (this.useProlongationTimer && this.timer) {
            this.timer.pauseAction();
        }
        this.prolongation.acceptOffer(this.offer);

        if (cleverapps.forces.isRunningForce()) {
            cleverapps.forces.closeRunningForce();
        }
    },

    needTimer: function () {
        if (!Game.currentGame) {
            return false;
        }

        if (["solitaire", "tile3", "blocks"].indexOf(cleverapps.config.type) === -1) {
            return false;
        }

        return cleverapps.forces.isShown(Forces.FREE_PROLONGATION_FORCE.id) || levels.user.getFloatLevel() >= 1.4;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    PROLONG_BUTTON_AMOUNT_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE
    },
    PROLONG_REWARDS_TEXT: {
        size: 50,
        name: "nostroke",
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    }
});

cleverapps.styles.ProlongationOfferView = {
    margin: 20,

    button: {
        width: 350,
        height: 90
    },

    label: {
        amount: {
            x: { align: "center", dx: -3 },
            y: { align: "center", dy: -3 }
        }
    },

    bonusLabel: {
        x: { align: "center", dx: 125 },
        y: { align: "center", dy: 73 }
    },

    question: {
        width: 730,
        height: 200,
        x: { align: "center" },
        y: { align: "center", dy: 125 }
    },

    rewards: {
        fg: {
            width: 860,
            height: 610
        },

        rewards: {
            x: { align: "center" },
            y: { align: "center" },
            iconWrap: {
                width: 170,
                height: 170
            }
        }
    }
};